import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { WrapperSmall, ButtonRoundedText, StyledInputWeekly } from '../styles/GlobalStyles';
import { StyledSection, StyledInputWrapper } from './Styles';

const variants = {
    hidden: {
        opacity: 0,
        y: 24,
    },
    visible: {
        opacity: 1,
        y: 0,
    },
};

const WeeklyRentStep = ({ formData, setFormData, onNextStep, setProgress }) => {
    useEffect(() => {
        setProgress("10%");
        window.scrollTo(0, 0);
    }, []); 

    const handleLocalNextStep = async () => {
        if (!formData.weeklyRent || isNaN(formData.weeklyRent) || formData.weeklyRent < 250) {
            toast.error('Please enter a valid weekly rent amount over $250.', {
                style: {
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#000',
                    backgroundColor: '#FFC701'
                }
            });
            return;
        }
        onNextStep();
    };

    return (
        <StyledSection
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{
                type: 'spring',
                stiffness: 180,
                damping: 10,
                mass: 0.75,
                delay: .2,
            }}
        >
            <WrapperSmall>
                <div className="titleContainer">
                    <h2>Let's get started <br/>unlocking your future rental income.</h2>
                </div>
                <label htmlFor="weeklyRent">Your Weekly Rental Income</label>
                <StyledInputWrapper>
                    <StyledInputWeekly
                        id="weeklyRent"
                        name="weeklyRent"
                        aria-label="weeklyRent"
                        autoFocus
                        type="number"
                        placeholder="Weekly Rent"
                        value={formData.weeklyRent || ''}
                        onChange={e => setFormData({ ...formData, weeklyRent: parseFloat(e.target.value) })}
                    />
                </StyledInputWrapper>
                <ButtonRoundedText onClick={handleLocalNextStep}>Next</ButtonRoundedText>
            </WrapperSmall>
        </StyledSection>
    );
};

export default WeeklyRentStep;