import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { WrapperSmall, ButtonRoundedText, StyledInputWeekly } from '../styles/GlobalStyles';
import { StyledSection, StyledInputWrapper } from './Styles';


const variants = {
    hidden: {
        opacity: 0,
        y: 24,
    },
    visible: {
        opacity: 1,
        y: 0,
    },
};

const WagesStep = ({ formData, setFormData, onNextStep, setProgress }) => {
    useEffect(() => {
        setProgress("82.5%");
        window.scrollTo(0, 0);
    }, []);

    const handleLocalNextStep = () => {
        if (!formData.combinedWages || isNaN(formData.combinedWages) || formData.combinedWages <= 0) {
            toast.error('Please enter a valid combined wages amount.', {
                style: {
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#000',
                    backgroundColor: '#FFC701'
                }
            });
            return;
        }
        onNextStep();
    };

    const formatDisplayNumber = (num) => {
        if (!num) return '';
        const parts = num.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join('.');
    };

    const handleInputChange = (e) => {
        const rawValue = e.target.value.replace(/,/g, '');
        setFormData({ ...formData, combinedWages: rawValue });
    };

    return (
        <StyledSection
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{
                type: 'spring',
                stiffness: 180,
                damping: 10,
                mass: 0.75,
                delay: .2,
            }}
        >
            <WrapperSmall>
                <div className="titleContainer">
                    <h2>Enter Combined Wages</h2>
                    <p>Please enter the combined wages of all property owners.</p>
                </div>
                <label htmlFor="combinedWages">Combined Wages</label>
                <StyledInputWrapper>
                    <StyledInputWeekly
                        id="combinedWages"
                        name="combinedWages"
                        type="text"
                        placeholder="Combined Wages"
                        value={formatDisplayNumber(formData.combinedWages || '')}
                        onChange={handleInputChange}
                    />
                </StyledInputWrapper>
                <ButtonRoundedText onClick={handleLocalNextStep}>Next</ButtonRoundedText>
            </WrapperSmall>
        </StyledSection>
    );
};

export default WagesStep;