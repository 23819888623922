import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledSection = styled(motion.section)`
    height: auto;
    width: 100%;
    z-index: 100;
    margin-bottom:160px;
    padding-top: 120px;
    .titleContainer {
        margin-bottom: 2em;
        h2 {
            margin-bottom: .2em;
        }
    }
    div {
        position: relative;
        label {
            text-align: left;
            padding: 1em 0;
            font-weight: 600;
        }
    }
`;

export const StyledInputWrapper = styled.div`
    position: relative;
    display: block;
    &::after {
        content: "$";
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        font-weight: 500;
        font-size: 1em;
    }
`;
